<template>
  <div class="spare-parts">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="备品备件仓库" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          style="margin-bottom: 0"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          @change="selectUnit"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: 0 }"
          @change="searchFn"
        />
        <!-- 备件/工具名称 -->
        <ml-input
          prop="partNameLike"
          placeholder="请输入备件名称"
          style="margin-bottom: 0; margin-right: 40px"
          label="备件名称:"
          v-model="searchData.partNameLike"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip class="default-tip" style="margin-bottom: 0; border: 0">
      <!-- 新增按钮 -->
      <template #title>
        <div class="tip-header">
          <el-button-group>
            <el-button
              v-if="userType === 'division_manager'"
              icon="el-icon-upload2"
              type="primary"
              @click="deviceExcelImport"
            >
              批量导入
            </el-button>
            <el-button icon="el-icon-download" type="primary" @click="deviceExcelExport">
              批量导出
            </el-button>
          </el-button-group>
          <el-button icon="el-icon-plus" type="primary" @click="clickAdd"> 新增备品备件 </el-button>
        </div>
      </template>
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(417)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="备件编号"
          prop="partCode"
          min-width="10%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="备件/工具名称" prop="partName" min-width="15%" />
        <el-table-column align="center" label="阿米巴" prop="companyName" min-width="15%" />
        <el-table-column
          align="center"
          label="所属项目"
          prop="projectName"
          min-width="30%"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          label="品牌/规格型号"
          prop="model"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="使用期限(月)" prop="deadline" width="100" />
        <el-table-column align="center" label="标准库存" prop="planStock" min-width="8%" />
        <el-table-column align="center" label="实际库存" prop="realStock" min-width="8%" />
        <el-table-column
          align="center"
          label="单位"
          prop="unit"
          min-width="10%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="生产日期" prop="productDate" width="100">
          <template #default="scope">
            {{ formetData(scope.row.productDate, 'year') }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="存放地址"
          prop="storageAddress"
          min-width="15%"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="出入库" priop="partId" width="120">
          <template #default="scope">
            <el-dropdown
              trigger="click"
              split-button
              size="small"
              type="primary"
              @click="clickAddSparePutRecord(scope.$index, scope.row)"
              @command="command => commandHandle(command, scope.$index, scope.row)"
            >
              入库
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="1">出库</el-dropdown-item>
                  <el-dropdown-item command="2">报废</el-dropdown-item>
                  <el-dropdown-item command="3">修改</el-dropdown-item>
                  <el-dropdown-item command="4" v-if="userType === 'division_manager'"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除设备提示框 -->
    <ml-dialog
      ref="deleteSparePartsDialogRef"
      :content="deleteSparePartsData.content"
      @click-submit="submitDeleteSpareParts"
    />
    <!-- 出库入库报废 -->
    <ml-dialog
      width="600px"
      ref="sparePartsRecordDialogRef"
      :title="sparePartsRecordData.title"
      :btnLoading="btnLoading"
      @click-submit="submitSparePartsRecord"
    >
      <template #body>
        <ml-form
          style="width: 100%; padding: 0 20px"
          labelWidth="100px"
          :model="sparePartsRecordData"
          ref="sparePartsFormRef"
          :rules="sparePartsFormRules"
        >
          <ml-select
            prop="processId"
            placeholder="请选择审批流程"
            :options="processData"
            label="审批流程:"
            keyName="processId"
            labelName="processName"
            valueName="processId"
            v-model="sparePartsRecordData.processId"
          />
          <!-- 数量 -->
          <ml-input-number
            prop="propertySn"
            placeholder="请输入数量"
            label="数量:"
            :min="0"
            v-model="sparePartsRecordData.number"
          />
          <!-- 原因 -->
          <ml-input
            prop="propertySn"
            placeholder="请输入原因"
            style="margin-bottom: 0"
            label="原因:"
            v-model="sparePartsRecordData.why"
            :maxlength="200"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 导入设备提示框 -->
    <ml-dialog width="600px" ref="importEquipmentDialogRef" title="批量导入" :showSubmitBtn="false">
      <template #body>
        <div class="import-equipment-dialog-box">
          <div class="import-equipment-dialog">
            <span>请选择导入的文件:</span>
            <ml-upload
              ref="importEquipmentUploadRef"
              :showFileList="false"
              @on-change="onChange"
              accept=".xls, .xlsx"
            >
              <el-button icon="el-icon-upload2" type="primary">批量导入</el-button>
            </ml-upload>
          </div>
          <div class="import-equipment-dialog">
            <span>请导入与部件详情信息表格匹配的文件还无模板？</span>
            <el-button type="text" @click="downloadModel">点击下载模板</el-button>
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  getUrlParamsString,
  formetData,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { FILEDOWNLOD, SPAREPARTEXCELEXPORT } from '@API'

export default {
  name: 'SpareParts',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const token = computed(() => getters.getAccessToken)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    const userType = computed(() => getters.getUserType)
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      partNameLike: getMemoryPage.value.searchData.partNameLike || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || ''
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getSparePartsTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getSparePartsTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 设备列表
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }

    const selectUnit = () => {
      searchData.projectId = ''
      searchFn()
    }
    watch(
      () => searchData.companyId,
      async newvalue => {
        if (newvalue) {
          await getProjectData(newvalue)
        }
      }
    )

    // 获取列表
    const getSparePartsTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.partNameLike = replacePerCent(searchData.partNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetspareParts',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getSparePartsTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 修改设备
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/sparePartsMAction', query: { partId: row.partId } })
    }
    // 添加设备
    const clickAdd = () => {
      clearMemoryPageFn(commit, searchData)
      router.push({ path: '/sparePartsMAction' })
    }
    // 删除
    const deleteSparePartsDialogRef = ref()
    const deleteSparePartsData = reactive({
      content: '确定删除该备品备件吗？'
    })
    const clickDelete = (index, row) => {
      dispatch('fetchExistsSpareProcess', { partId: row.partId, operateType: 3 }).then(data => {
        if (data.code === 200) {
          let num = data.data
          if (num > 0) {
            commit('setError', {
              status: true,
              title: '',
              message: '此备品备件有未处理完的流程',
              type: 'warning'
            })
          } else {
            deleteSparePartsData.partId = row.partId
            deleteSparePartsDialogRef.value.showDialog = true
          }
        }
      })
    }
    // 确定删除
    const submitDeleteSpareParts = () => {
      const { partId } = deleteSparePartsData
      dispatch('fetchDeleteSparePart', partId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getSparePartsTabberData() : (tabberData.page = 1)
          deleteSparePartsDialogRef.value.showDialog = false
        }
      })
    }

    // 出库入库报废记录
    // 表单校验
    let btnLoading = ref(false)
    const sparePartsFormRules = {
      processId: [{ required: true, message: '请选择审批流程', trigger: 'blur' }],
      number: [{ required: true, message: '请输入数量', trigger: 'blur' }]
    }
    const processData = ref([])
    const sparePartsRecordDialogRef = ref()
    const sparePartsFormRef = ref()
    const sparePartsRecordData = reactive({
      title: '',
      number: 0,
      why: '',
      processId: '',
      projectId: '',
      businessType: 'spare_part',
      nodeName: ''
    })
    const getProcessList = companyId => {
      dispatch('fetchOrderProcesss', { companyId }).then(data => {
        if (data.code === 200) {
          processData.value = data.data || []
        }
      })
    }
    const commandHandle = (command, index, row) => {
      if (command == 1) {
        clickAddSpareOutRecord(index, row)
      } else if (command == 2) {
        clickAddSpareScrapRecord(index, row)
      } else if (command == 3) {
        clickUpdate(index, row)
      } else if (command == 4) {
        clickDelete(index, row)
      }
    }
    // 出库
    const clickAddSpareOutRecord = (index, row) => {
      dispatch('fetchExistsSpareProcess', { partId: row.partId, operateType: 2 }).then(data => {
        if (data.code === 200) {
          let num = data.data
          if (num > 0) {
            commit('setError', {
              status: true,
              title: '',
              message: '此备品备件有未处理完的出库流程',
              type: 'warning'
            })
          } else {
            sparePartsRecordDialogRef.value.showDialog = true
            sparePartsRecordData.title = '出库记录'
            sparePartsRecordData.nodeName = '【出库】'
            sparePartsRecordData.number = 0
            sparePartsRecordData.why = ''
            sparePartsRecordData.partId = row.partId
            sparePartsRecordData.projectId = row.projectId
          }
        }
      })
    }
    // 报废
    const clickAddSpareScrapRecord = (index, row) => {
      dispatch('fetchExistsSpareProcess', { partId: row.partId, operateType: 2 }).then(data => {
        if (data.code === 200) {
          let num = data.data
          if (num > 0) {
            commit('setError', {
              status: true,
              title: '',
              message: '此备品备件有未处理完的出库流程',
              type: 'warning'
            })
          } else {
            sparePartsRecordDialogRef.value.showDialog = true
            sparePartsRecordData.title = '报废记录'
            sparePartsRecordData.nodeName = '【报废】'
            sparePartsRecordData.number = 0
            sparePartsRecordData.why = ''
            sparePartsRecordData.partId = row.partId
            sparePartsRecordData.projectId = row.projectId
          }
        }
      })
    }
    // 入库
    const clickAddSparePutRecord = (index, row) => {
      dispatch('fetchExistsSpareProcess', { partId: row.partId, operateType: 1 }).then(data => {
        if (data.code === 200) {
          let num = data.data
          if (num > 0) {
            commit('setError', {
              status: true,
              title: '',
              message: '此备品备件有未处理完的入库流程',
              type: 'warning'
            })
          } else {
            sparePartsRecordDialogRef.value.showDialog = true
            sparePartsRecordData.title = '入库记录'
            sparePartsRecordData.nodeName = '【入库】'
            sparePartsRecordData.number = 0
            sparePartsRecordData.why = ''
            sparePartsRecordData.partId = row.partId
            sparePartsRecordData.projectId = row.projectId
          }
        }
      })
    }
    // 提交出库
    const submitSparePartsRecord = () => {
      btnLoading.value = true
      let actionName = ''
      const { number, why, partId, title, processId, projectId, nodeName } = sparePartsRecordData
      const nParams = { partId }
      nParams.orderProcess = {
        processId,
        projectId,
        businessType: 'spare_part',
        nodeName,
        content: why
      }
      if (title === '出库记录') {
        nParams.planOutStock = number
        nParams.outReason = why
        nParams.remark = '出库'
        actionName = 'fetchAddSpareOutRecord'
      } else if (title === '报废记录') {
        nParams.planOutStock = number
        nParams.outReason = why
        nParams.remark = '报废'
        actionName = 'fetchAddSpareOutRecord'
      } else if (title === '入库记录') {
        nParams.planPutStock = number
        nParams.putReason = why
        nParams.remark = '入库'
        actionName = 'fetchAddSparePutRecord'
      }
      sparePartsFormRef.value.CustomFormRef.validate()
        .then(() => {
          dispatch(actionName, nParams).then(data => {
            btnLoading.value = false
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              tabberData.page === 1 ? getSparePartsTabberData() : (tabberData.page = 1)
              sparePartsRecordDialogRef.value.showDialog = false
            }
          })
        })
        .catch(err => {
          btnLoading.value = false
        })
    }

    // 下载模板
    const downloadModel = () => {
      window.location.href = `${FILEDOWNLOD}?fileType=sparePart`
    }

    // 导入
    const importEquipmentDialogRef = ref()
    const importEquipmentUploadRef = ref()
    const deviceExcelImport = () => {
      importEquipmentDialogRef.value.showDialog = true
    }
    const onChange = (ev, file) => {
      if (file && file.length > 0) {
        const ImportFormData = new FormData()
        ImportFormData.append('file', file[0].raw)
        importEquipmentUploadRef.value.CustomUploadRef.uploadFiles = []
        dispatch('fetchSparePartExcelImport', ImportFormData).then(data => {
          if (data && data.code === 200) {
            tabberData.page === 1 ? getSparePartsTabberData() : (tabberData.page = 1)
            importEquipmentDialogRef.value.showDialog = false
          }
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
        })
      }
    }

    // 导出
    const deviceExcelExport = () => {
      const getUrlParams = getUrlParamsString(searchParams(searchData))
      const exportUrl = getUrlParams
        ? `${SPAREPARTEXCELEXPORT}?token=${encodeURIComponent(
            token.value
          )}&access_token=${encodeURIComponent(token.value)}&${getUrlParams}`
        : `${SPAREPARTEXCELEXPORT}?token=${encodeURIComponent(
            token.value
          )}&access_token=${encodeURIComponent(token.value)}`
      window.location.href = exportUrl
    }
    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      if (getMemoryPage.value.searchData.companyId) {
        await getProjectData(getMemoryPage.value.searchData.companyId)
      }

      getSparePartsTabberData()
      getProcessList()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickUpdate,
      clickDelete,
      deleteSparePartsData,
      deleteSparePartsDialogRef,
      submitDeleteSpareParts,
      clickAdd,
      importEquipmentDialogRef,
      deviceExcelImport,
      deviceExcelExport,
      onChange,
      downloadModel,
      sparePartsRecordDialogRef,
      sparePartsRecordData,
      formetData,
      clickAddSpareOutRecord,
      submitSparePartsRecord,
      clickAddSpareScrapRecord,
      clickAddSparePutRecord,
      importEquipmentUploadRef,
      unitOptions,
      projectData,
      selectUnit,
      commandHandle,
      sparePartsFormRules,
      processData,
      getProcessList,
      sparePartsFormRef,
      userType,
      btnLoading
    }
  }
}
</script>

<style lang="scss" scoped>
.spare-parts {
  @extend %params-global;
}
.import-equipment-dialog-box {
  width: 100%;
  padding: 0 20px;
  .import-equipment-dialog {
    width: 100%;
    display: flex;
    // justify-content: start;
    align-items: center;
    span {
      margin-right: 20px;
    }
    &:first-child {
      margin-bottom: 20px;
    }
  }
}
/deep/ .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:hover {
  background: #ecf5ff;
  color: #409eff;
}
</style>
